<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">订单退费记录</div>
      <div class="formDiv" style="width: 70vw; margin-left: 10vw">
        <el-form label-position="left" label-width="90px">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="订单号:">
                <el-input
                  v-model="FormData.order_id"
                  placeholder="订单编号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="用户手机号:">
                <el-input
                  v-model="FormData.phone"
                  placeholder="请输入手机号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
                <el-form-item label="设备编号:">
                  <el-input
                    v-model="FormData.device_id"
                    placeholder="请输入设备编号"
                  ></el-input>
                </el-form-item>
              </el-col> -->
            <el-col :span="6">
              <el-form-item label="车型:" >
                <el-select
                  v-model="FormData.type_id"
                  placeholder="请选择充电车型"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="car in carList"
                    :key="car.value"
                    :value="car.value"
                    :label="car.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form label-position="left" label-width="90px">
          <el-row :gutter="20">
            <el-col v-show="isadmin" :span="6">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.username"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单时间：" class="time_pick">
                <el-date-picker
                  style="height: 40px; width: 100%"
                  v-model="FormData.orderTime"
                  type="daterange"
                  unlink-panels
                  range-separator=" - "
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  size="small"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1" style="margin-left:20px">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
            <el-col :span="1" style="margin-left:30px">
              <Data2Excel api='/report/order_refund_detail' :data="dataa" fileName="订单退费记录"></Data2Excel>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="510px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="订单号" prop="out_trade_no" />
          <el-table-column label="退费单号" prop="out_refund_no" />
          <el-table-column label="用户手机号" prop="phone" />
          <el-table-column label="用户编号" prop="user_number" />
          <el-table-column
            label="总金额(元)"
            prop="total_amount"
            :formatter="rounding"
          />
          <!-- <el-table-column
            label="实付金额(元)"
            prop="pay_amount"
            :formatter="rounding"
          /> -->
          <el-table-column
            label="退费金额(元)"
            prop="refund_amount"
            :formatter="rounding"
          />
          <el-table-column label="退费时间" prop="create_time" width="160px"/>
          <el-table-column label="退费原因" prop="refund_reason" />
          <!-- <el-table-column label="设备编号" prop="device_id" /> -->
          <el-table-column label="充电车型" prop="type_id">
            <template #default="scope">
              <span v-if="scope.row.type_id == 2">电动车</span>
              <span v-if="scope.row.type_id == 4">电动汽车</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="充电端口" prop="socket" width="80" /> -->
          <el-table-column label="所属运营商" prop="username" />
          <el-table-column label="退费操作员" prop="username" />
          <!-- <el-table-column
            label="操作"
            width="240"
            align="center"
            
          >
            <template #default="scope">
              <div
                class="BtnSearch1 BtnSearch"
                @click="torefund(scope.$index, scope.row)"
                >退费</div
              >
             
            </template>
          </el-table-column> -->
        </el-table>
        <div class="pagination">
          <div class="flex1">
            <p>
              退费总次数：<span>{{
                tableData1.count_re_order ? tableData1.count_re_order : 0
              }}</span>
            </p>
            <p>
              退费总金额(元)：<span>{{
                tableData1.sum_re_order ? tableData1.sum_re_order : "0.00"
              }}</span>
            </p>
          </div>
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { onMounted } from "vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import { order_refund_detail, operator_data } from "@/request/api";
import { ElMessage } from "element-plus";
import ProvinceCityCountry from "../../assets/json/address";
import Data2Excel from "@/component/Data2Excel.vue"
export default {
  name: "UserRecord",
  components:{
    Data2Excel
  },
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        orderTime: ["", ""],
      },
      isadmin: false,
      users: [],
      tableData: [],
      tableData1: {},
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      car: "",

      loading: false,
      provinceData: ProvinceCityCountry.address,
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,
      dataa:{}
    });
    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const searchBtn = () => {
      data.currentPage = 1;
      getList();
    };

    // const handleExcel = () => {
    //   console.log('导出');
    // };

    // const handlePrint = () => {
    //   console.log('打印');
    // };

    const getList = () => {
      let stDate;
      let endDate;
      if (data.FormData.orderTime) {
        stDate = data.FormData.orderTime[0] ? data.FormData.orderTime[0] : "";
        endDate = data.FormData.orderTime[1] ? data.FormData.orderTime[1] : "";
      } else if (
        data.FormData.orderTime === null ||
        data.FormData.orderTime === undefined ||
        data.FormData.orderTime === ""
      ) {
        stDate = "";
        endDate = "";
      }
      data.dataa = {
        username: data.FormData.username,
        operator_id: sessionStorage.getItem("userID"),
        phone: data.FormData.phone,
        type_id: data.FormData.type_id,
        //device_id:data.FormData.device_id,
        order_id: data.FormData.order_id,
        start_execute_date: stDate,
        stop_execute_date: endDate,
        page: data.currentPage,
      };
      data.loading = true;
      order_refund_detail(data.dataa).then((res) => {
        console.log(res, "实时订单列表");
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData1 = res;
            data.tableData1.sum_re_order = Number(res.sum_re_order)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            data.tableData = res.data;
            data.total = res.count_re_order;
            data.tableData.forEach((a)=>{
              a.total_amount = Number(a.total_amount )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              a.refund_amount = Number(a.refund_amount )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            })
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };

    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };
    const handleSizeChange = (val) => {
      console.log(val, "vallllllhandleSizeChange");
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList();
      //
    };
    const formatDate = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let dt = new Date(data);
      return (
        dt.getFullYear() +
        "-" +
        (dt.getMonth() + 1) +
        "-" +
        dt.getDate() +
        " " +
        dt.getHours() +
        ":" +
        dt.getMinutes() +
        ":" +
        dt.getSeconds()
      );
    };

    onMounted(() => {
      getUsername();
      getList();
    });
    return {
      ...toRefs(data),
      searchBtn,
      formatDate,
      getUsername,
      handleSizeChange,
      handleCurrentChange,
      rounding,
    };
  },
};
</script>

<style>
.formDiv {
  width: 75%;
}
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}
.bottomDiv {
  height: 100%;
}
.flex1 {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  /* margin-top: -10px; */
}
.flex1 p {
  font-size: 14px;
  margin-right: 15px;
}
.flex1 span {
  color: red;
}
</style>